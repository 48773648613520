/* General Styling */
/* $custom-colors: (
    "custom-atomic-tangerine": #FCA17D,
    "custom-lavender": #DADFF7,
    "custom-flax": #FFEE93,
    "custom-slate-grey": #254441,
    "custom-oxford-blue": #020122,
    ); */

.header {
  background-color: #dadff7;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #020122;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.hero-cover {
  position: relative;
  max-height: 520px;
  overflow: hidden;
}
/* END General Styling */

/* Jumbotrons */

.search-jumbotron {
  background-image: url(https://images.pexels.com/photos/6210505/pexels-photo-6210505.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  position: relative;
  height: 520px;
}

.options-jumbotron {
  background-image: url(https://images.pexels.com/photos/4518646/pexels-photo-4518646.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  position: relative;
  height: 520px;
}

.match-made-jumbotron {
  background-image: url(https://images.pexels.com/photos/347139/pexels-photo-347139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom;
  position: relative;
  height: 520px;
}
/* END Jumbotrons */

/* Containers */

.home-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  border-radius: 3%;
  border: 10px;
  border-color: #dadff7;
  text-align: center;
  background-color: rgba(252, 161, 125, 0.7);
}

.search-container {
  background-color: rgba(252, 161, 125, 0.7);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.options-container {
  background-color: #dadff7;
  padding: 1%;
  position: absolute;
}

.match-view-container {
  background-color: #dadff700;
  background-size: cover;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  width: 100%;
  height: 520px;
  padding: 0;
  column-gap: 2%;
  justify-content: flex-start;
  padding: 2rem 2rem 2rem 0;
}

.matched-container {
  background-color: rgba(218, 223, 247, 0.6);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100%;
  width: 70%;
  flex: 1 1 20rem;
}

.match-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 30%;
}

/* END Containers */

/*Text Styling*/

.fynder-dark-text {
  color: #020122;
}

.fynder-light-text {
  color: #dadff7;
}

.fynder-slate-text {
  color: #254441;
}

.text-top {
  vertical-align: top;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  font-size: 3rem;
  white-space: nowrap;
  opacity: 1;
}

h2 {
  text-align: center;
  margin: 10px;
}

h4 {
  margin: 10px;
  font-size: medium;
}

/*END Text Styling*/

/*3D Buttons*/

.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
  color: white;
  font-size: medium;
}

.btn3d.btn:hover {
  color: #020122;
  background-color: #dadff7;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}
.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn {
  background-color: #020122;
}
.btn3d.btn:active,
.btn3d.btn.active {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: #dadff7;
}
/*END 3D Buttons*/

/*Form Wizard*/
.bs-wizard {
  margin-top: 40px;
  color: #dadff7;
  outline: none;
}

.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
  border-bottom: 0;
}
.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #254441;
  font-size: 16px;
  margin-bottom: 5px;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #dadff7;
  font-size: 14px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 45px;
  height: 45px;
  display: block;
  background: rgba(252, 161, 125, 0.3);
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: " ";
  width: 28px;
  height: 28px;
  background: #fca17d;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: rgba(252, 161, 125, 0.7);
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #dadff7;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}
/*END Form Wizard*/

/* Badges */
.top-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 5%;
  /* height: 100%; */
  /* padding: 5%; */
  flex: 1 1 20%;
}
