.badge-card {
    background: radial-gradient(ellipse, #57a39c 30%,#57a39b62 60%, #57a39c 90%);
    height: 100%;
    position: relative;
    width: 25vw;
    display: flex;
    flex-direction: column;
    gap: 10%;
    padding: 5%;
}

.badge-card .PIC {
    height: 20%;
    text-align: center;
    width: 100%;
}

.badge-card .username {
    font-size: 100%;
    font-weight: 400;
    height: 15%;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}


.badge-card .badge-award {
    height: 40%;
    width: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
}
