.place-info-bg {
    position: absolute;
    display: flex;
    gap: 2%;
    z-index: 3;
    top: 6%;
    left: 5%;
    width: 80vw;
    height: 90%;
    padding: .5rem;
}

.place-info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 100%;
  gap: 3%;
  align-items: center;
  justify-items: center;
  background: #dadff7;
}

.check-img {
  display: inline-block;
  max-width: 2.5rem;
  border: 2px solid black;
}

.features {
  display: flex;
  font-size: 1.5vw;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  gap: 20%;
  width: 50rem;
}

.feature-block {
  display: inline-block;
  padding: 0 0 0 3px;
  border: ridge rgba(0, 0, 0, 0.178);
}

.close-info {
    display: flex;
    flex-direction: column;
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: #DADff7;
    align-items: center;
    justify-content: center;
    gap: 5%;
    width: 3%;
    height: 100%;
    background: #020122;
}
